// managementCompany.js
import { postRequest, deleteRequest, putRequest } from './index'
// import { scfURL } from '@/systemConfiguration/index'
// 获取资金方列表  - 搜索
export const obtainManagementCompany = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/funder/page',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 新增资金方
export const addAndManagementCompany = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/funder/add',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 编辑资金方
export const putManagementCompany = (
  data,
  successCallback,
  failureCallback
) => {
  putRequest(
    '/funder/edit',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 删除资金方
export const deleteManagementCompany = (id, successCallback, failureCallback) => {
  deleteRequest(`/funder/delete/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

